import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const ReadOnlyWrapper = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  const isReadOnlyRole =
    currentUser?.staffData?.role === 'audit' ||
    currentUser?.staffData?.role === 'compliance';

  // If the user has a read-only role, disable all input elements
  if (isReadOnlyRole) {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          onClick: (e) => {
            // Prevent default for buttons and links
            if (e.target.tagName === 'BUTTON' || e.target.tagName === 'A') {
              e.preventDefault();
            }
          },
          onSubmit: (e) => {
            // Prevent form submissions
            e.preventDefault();
          },
          style: {
            ...child.props.style,
            pointerEvents: 'none',
            opacity: 0.7,
          },
        });
      }
      return child;
    });
  }

  return children;
};

export default ReadOnlyWrapper;
