export const userInputs = [
  {
    id: 'displayName',
    label: 'Name and surname',
    type: 'text',
    placeholder: 'John Doe',
  },
  {
    id: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'John Doe',
    isRequired: false,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'John Doe',
    isRequired: false,
  },
  {
    id: 'dob',
    label: 'Date of Birth',
    type: 'date',
    placeholder: 'DD/MM/YYYY',
  },
  {
    id: 'gender',
    label: 'Gender',
    type: 'select',
    options: [
      { value: '', label: 'Select Gender' },
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
      { value: 'group', label: 'Group' },
    ],
  },
  {
    id: 'trust',
    label: 'Beneficiary (Type)',
    type: 'select',
    options: [
      { value: '', label: 'Select Beneficiary' },
      { value: 'individual', label: 'Individual' },
      { value: 'corporation', label: 'Corporation' },
    ],
  },
  // Corporation-specific fields should NOT be here
  {
    id: 'email',
    label: 'Email',
    type: 'mail',
    placeholder: 'john_doe@gmail.com',
  },
  {
    id: 'phone',
    label: 'Phone',
    type: 'text',
    placeholder: '+233 234 567 89',
  },
  {
    id: 'address',
    label: 'Address',
    type: 'text',
    placeholder: 'Golf. St. Accra',
  },
  {
    id: 'digital',
    label: 'Digital Address',
    type: 'text',
    placeholder: 'EH-000-0254',
  },
  {
    id: 'account',
    label: 'Account',
    type: 'text',
    placeholder: '2611000061234567',
    isRequired: false,
  },
  {
    id: 'dop',
    label: 'Date of Purchase',
    type: 'date',
    placeholder: 'DD/MM/YYYY',
  },
  {
    id: 'cert',
    label: 'Certificate Number',
    type: 'text',
    placeholder: 'e.g 0000250',
    isRequired: false,
  },
  {
    id: 'channel',
    label: 'Channel of Dividend Payment',
    type: 'select',
    options: [
      { value: '', label: 'Select Payment Channel' },
      { value: 'cash', label: 'Cash' },
      { value: 'momo', label: 'MoMo' },
      { value: 'bank_transfer', label: 'Bank Transfer' },
      { value: 'draft', label: 'Draft' },
    ],
  },
  {
    id: 'code',
    label: 'Branch Code',
    type: 'select',
    options: [
      { value: '', label: 'Select a branch code' },
      { value: '116', label: 'Nkawkaw' },
      { value: '117', label: 'Praso' },
      { value: '111', label: 'Ejisu' },
      { value: '118', label: 'Anyinam' },
      { value: '114', label: 'Mpraeso' },
      { value: '112', label: 'Obo' },
      { value: '113', label: 'Tafo' },
    ],
  },
  {
    id: 'share',
    label: 'Number of Shares',
    type: 'text',
    placeholder: '100',
  },
  {
    id: 'price',
    label: 'Share Amount',
    type: 'text',
    placeholder: 'GH₵',
  },
  {
    id: 'card',
    label: 'Ghana Card',
    type: 'text',
    placeholder: 'GHA-000123456-7',
    isRequired: false,
  },
  {
    id: 'kin',
    label: 'Next of Kin',
    type: 'text',
    placeholder: 'John Doe',
  },
  {
    id: 'kin-contact',
    label: "Next of Kin's Contact",
    type: 'text',
    placeholder: '233 50254 1210',
  },

  {
    id: 'inputter',
    label: 'Name of Inputer',
    type: 'text',
    placeholder: 'John Doe',
  },
];
