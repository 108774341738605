import './updateUser.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useEffect, useState } from 'react';
import { doc, serverTimestamp, updateDoc, getDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import Loading from '../../components/Loading/Loading';
import useRolePermissions from '../../hooks/useRolePermissions';
import ReadOnlyForm from '../../components/ReadOnlyForm/ReadOnlyForm';

const UpdateUser = ({ inputs, title }) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    status: '',
    img: '',
    price: '',
    share: '',
    trust: 'individual',
  });
  const [additionalData, setAdditionalData] = useState({
    price: '',
    shares: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { userId } = useParams();
  const [originalPrice, setOriginalPrice] = useState(0);
  const [originalShares, setOriginalShares] = useState(0);
  const { isReadOnlyRole } = useRolePermissions();

  const handleInput = (e) => {
    const { id, value } = e.target;

    if (id === 'additionalPrice') {
      setAdditionalData((prev) => ({ ...prev, price: value }));
      setData((prevData) => ({
        ...prevData,
        price: Number(originalPrice) + Number(value),
      }));
    } else if (id === 'additionalShares') {
      setAdditionalData((prev) => ({ ...prev, shares: value }));
      setData((prevData) => ({
        ...prevData,
        share: Number(originalShares) + Number(value),
      }));
    } else {
      setData((prevData) => ({ ...prevData, [id]: value }));

      if (id === 'trust' && value === 'individual') {
        setData((prevData) => ({
          ...prevData,
          corpName: '',
          doi: '',
          tax: '',
        }));
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, 'users', userId), {
        ...data,
        timeStamp: serverTimestamp(),
      });

      if (additionalData.price || additionalData.shares) {
        const transactionsCollection = collection(
          doc(db, 'users', userId),
          'transactions'
        );
        await addDoc(transactionsCollection, {
          priceAdded: additionalData.price,
          sharesAdded: additionalData.shares,
          totalPrice: Number(originalPrice) + Number(additionalData.price),
          totalShares: Number(originalShares) + Number(additionalData.shares),
          timeStamp: serverTimestamp(),
        });

        setAdditionalData({ price: '', shares: '' });
      }

      navigate(-1);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const docSnap = await getDoc(doc(db, 'users', userId));
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setData(userData);
          setOriginalPrice(userData.price);
          setOriginalShares(userData.share);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [userId]);

  if (isLoading) return <Loading />;

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                data.img ||
                'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=""
            />
          </div>
          <div className="right">
            <ReadOnlyForm onSubmit={handleUpdate}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: 'none' }}
                  autoComplete="off"
                />
              </div>

              <div className="formInput">
                <label>Beneficiary Type</label>
                <select
                  id="trust"
                  value={data.trust || 'individual'}
                  onChange={handleInput}
                >
                  <option value="individual">Individual</option>
                  <option value="corporation">Corporation</option>
                </select>
              </div>

              <div className="formInput">
                <label htmlFor="additionalPrice">Additional Share Value</label>
                <input
                  id="additionalPrice"
                  type="text"
                  placeholder="Enter Additional Share Value"
                  onChange={handleInput}
                  value={additionalData.price || ''}
                  autoComplete="off"
                  disabled
                />
              </div>

              <div className="formInput">
                <label htmlFor="additionalShares">
                  Additional Number of Shares
                </label>
                <input
                  id="additionalShares"
                  type="text"
                  placeholder="Enter Additional Shares"
                  onChange={handleInput}
                  value={additionalData.shares || ''}
                  autoComplete="off"
                  disabled
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label htmlFor={input.id}>{input.label}</label>
                  <input
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                    onChange={handleInput}
                    value={data[input.id] || ''}
                    autoComplete="off"
                    disabled={input.id === 'price' || input.id === 'share'}
                  />
                </div>
              ))}

              {data.trust === 'corporation' && (
                <>
                  <div className="formInput">
                    <label>Name of Corporation</label>
                    <input
                      id="corpName"
                      type="text"
                      placeholder="e.g Kwahu Praso Rural bank Ltd"
                      onChange={handleInput}
                      value={data.corpName || ''}
                    />
                  </div>
                  <div className="formInput">
                    <label>Date of Incorporation</label>
                    <input
                      id="doi"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      onChange={handleInput}
                      value={data.doi || ''}
                    />
                  </div>
                  <div className="formInput">
                    <label>Tax Identification No:</label>
                    <input
                      id="tax"
                      type="text"
                      placeholder="GH-00-0000"
                      onChange={handleInput}
                      value={data.tax || ''}
                    />
                  </div>
                </>
              )}

              <button
                type="submit"
                className={`submitBtn ${isReadOnlyRole ? 'disabled' : ''}`}
                disabled={isReadOnlyRole}
              >
                Update
              </button>
            </ReadOnlyForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
