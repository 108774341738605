import { useContext, useState } from 'react';
import './login.scss';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const auth = getAuth();
  const db = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(false);
    setErrorMessage('');
    setIsLoading(true);

    try {
      console.log('Attempting login with:', email);

      // First, authenticate with Firebase Auth
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log('Firebase Auth successful:', userCredential.user.uid);

      // Check if user exists in staff collection
      const staffDocRef = doc(db, 'staff', userCredential.user.uid);
      const staffDoc = await getDoc(staffDocRef);
      console.log('Staff doc exists:', staffDoc.exists());

      if (staffDoc.exists()) {
        const staffData = staffDoc.data();
        console.log('Staff data:', staffData);

        // Dispatch login action with both user and staff data
        dispatch({
          type: 'LOGIN',
          payload: {
            uid: userCredential.user.uid,
            email: userCredential.user.email,
            staffData: staffData,
          },
        });

        // Store auth state in localStorage
        localStorage.setItem(
          'user',
          JSON.stringify({
            uid: userCredential.user.uid,
            email: userCredential.user.email,
            staffData: staffData,
          })
        );

        setIsLoading(false);
        navigate('/');
      } else {
        console.log('User not found in staff collection');
        await auth.signOut();
        setErrorMessage('Access denied. Only staff members can login.');
        setError(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage(
        error.code === 'auth/wrong-password' ||
          error.code === 'auth/user-not-found'
          ? 'Wrong email or password!'
          : 'An error occurred during login. Please try again.'
      );
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <div className="login">
      <div className="write">
        <h2>Welcome Back!</h2>
        <span>Kwahu Praso Rural Bank</span>
        <p>Shareholder's Management System</p>
      </div>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email *"
          required
          onChange={(e) => setEmail(e.target.value)}
          disabled={isLoading}
        />
        <input
          type="password"
          placeholder="Password *"
          required
          onChange={(e) => setPassword(e.target.value)}
          disabled={isLoading}
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
        {error && <span className="error">{errorMessage}</span>}
      </form>
    </div>
  );
};

export default Login;
