import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { SearchContext } from '../../context/SearchContext';
import './datatable.scss';
import { userColumns } from '../../datatablesource';
import { BeatLoader } from 'react-spinners';
import useRolePermissions from '../../hooks/useRolePermissions';

const Datatable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const { search } = useContext(SearchContext);
  const pageSize = 10; // Adjust based on your UI design
  const { isReadOnlyRole } = useRolePermissions();

  useEffect(() => {
    if (auth.currentUser && currentUserRole === null) {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setCurrentUserRole(docSnap.data().role);
        }
      });
    }

    let usersQuery = collection(db, 'users');
    usersQuery = query(usersQuery, orderBy('displayName'), limit(pageSize));

    if (search.trim() !== '') {
      usersQuery = query(
        usersQuery,
        where('displayName', '>', search),
        where('displayName', '<=', search + '\uf8ff'),
        limit(pageSize)
      );
    }

    const unsub = onSnapshot(usersQuery, (snapShot) => {
      const list = snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setData(list);
      setLoading(false);
    });

    return () => unsub();
  }, [search, currentUserRole]); // Dependency on search and user role

  const approveUser = (userId) => {
    const userRef = doc(db, 'users', userId);
    updateDoc(userRef, { status: 'approved' });
  };

  const handleDelete = async (userId) => {
    if (isReadOnlyRole) {
      return; // Don't allow deletion for read-only roles
    }

    try {
      if (window.confirm('Are you sure you want to delete this user?')) {
        await deleteDoc(doc(db, 'users', userId));
        setData(data.filter((item) => item.id !== userId));
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete user. Please try again.');
    }
  };

  const exportToCsv = async () => {
    setLoading(true);
    const allUsersQuery = query(
      collection(db, 'users'),
      orderBy('displayName')
    );
    const querySnapshot = await getDocs(allUsersQuery);
    const allUsersData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // CSV export logic with data cleaning
    const csvRows = [];
    const headers = Object.keys(allUsersData[0]);
    csvRows.push(headers.join(','));

    for (const row of allUsersData) {
      const values = headers.map((header) => {
        let value = '' + row[header]; // Convert to string

        // Clean and format the data
        value = value.trim(); // Remove leading/trailing spaces
        if (!isNaN(value) && value !== '') {
          value = Number(value); // Convert to number if applicable
        }

        const escaped = value.toString().replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'shareholders-data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/users/${params.row.id}`}
              style={{ textDecoration: 'none' }}
            >
              <div className="viewButton">View</div>
            </Link>
            {!isReadOnlyRole && (
              <>
                <Link
                  to={`/users/${params.row.id}/transfer`}
                  style={{ textDecoration: 'none' }}
                  className={isReadOnlyRole ? 'disabled-link' : ''}
                  onClick={(e) => isReadOnlyRole && e.preventDefault()}
                >
                  <div
                    className={`transferButton ${
                      isReadOnlyRole ? 'disabled' : ''
                    }`}
                  >
                    Transfer
                  </div>
                </Link>
                <div
                  className={`deleteButton ${isReadOnlyRole ? 'disabled' : ''}`}
                  onClick={() => !isReadOnlyRole && handleDelete(params.row.id)}
                >
                  Delete
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const filteredData = data; // Server-side filtering makes client-side filtering redundant

  return (
    <div className="datatable">
      {loading ? (
        <div className="loadingState">
          <BeatLoader color={'#123abc'} />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <div className="datatableTitle">
            Shareholders
            {!isReadOnlyRole && (
              <Link to="/users/new" className="link">
                Add New
              </Link>
            )}
            <button onClick={exportToCsv} className="exportButton">
              Export to CSV
            </button>
          </div>
          <DataGrid
            className="datagrid"
            rows={filteredData}
            columns={userColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </>
      )}
    </div>
  );
};

export default Datatable;
