import './allTransactions.scss';
import { CSVLink } from 'react-csv';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useEffect, useState } from 'react';
import {
  query,
  collection,
  getDocs,
  orderBy,
  where,
  doc,
  getDoc,
} from 'firebase/firestore';
import { auth, db } from '../../firebase';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';

const AllTransactions = ({ type }) => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branchFilter, setBranchFilter] = useState('');
  const [currentStaff, setCurrentStaff] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let data;

  switch (type) {
    case 'user':
      data = {
        title: 'User Transactions',
        isMoney: false,
        link: 'View More',
        query: 'users',
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: 'crimson',
              backgroundColor: 'rgba(255, 0, 0, 0.2)',
            }}
          />
        ),
      };
      break;
    default:
      data = {
        title: '',
        isMoney: false,
        link: '',
        query: '',
        icon: null,
      };
  }

  useEffect(() => {
    let isMounted = true;

    if (auth.currentUser) {
      const staffDocRef = doc(db, 'staff', auth.currentUser.uid);
      getDoc(staffDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            setCurrentStaff(docSnap.data());
          }
        })
        .catch((error) => {
          console.error('Error fetching staff:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    const fetchData = async () => {
      try {
        if (type === 'user') {
          const allUsersQuery = query(
            collection(db, data.query),
            where('status', '==', 'approved'),
            orderBy('timeStamp', 'desc')
          );

          const allUserData = await getDocs(allUsersQuery);

          let calculatedTotalPrice = 0;
          let calculatedTotalVolume = 0;

          const usersData = allUserData.docs.map((doc) => {
            const userData = doc.data();

            if (typeof userData.price === 'number') {
              calculatedTotalPrice += userData.price;
            } else if (typeof userData.price === 'string') {
              const cleanedPrice = userData.price.replace(/[^\d.-]/g, '');
              const userTotalTraded = parseFloat(cleanedPrice);
              if (!isNaN(userTotalTraded)) {
                calculatedTotalPrice += userTotalTraded;
              }
            }

            const userVolume = parseInt(userData.share, 10);
            if (!isNaN(userVolume)) {
              calculatedTotalVolume += userVolume;
            }

            // Format date
            if (userData.timeStamp?.toDate) {
              userData.formattedDate = userData.timeStamp
                .toDate()
                .toLocaleDateString();
              userData.formattedTime = userData.timeStamp
                .toDate()
                .toLocaleTimeString();
            }

            return userData;
          });

          if (isMounted) {
            setTotalPrice(calculatedTotalPrice.toFixed(2));
            setTotalVolume(calculatedTotalVolume);
            setAllUsers(usersData);
            setTotalUsers(usersData.length);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [type, data.query]);

  useEffect(() => {
    if (auth.currentUser) {
      const staffDocRef = doc(db, 'staff', auth.currentUser.uid);
      getDoc(staffDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            setCurrentStaff(docSnap.data());
          }
        })
        .catch((error) => {
          console.error('Error fetching staff:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const totalPages = Math.ceil(allUsers.length / itemsPerPage);

  const filteredUsers = allUsers.filter((user) => {
    if (startDate && new Date(user.formattedDate) < new Date(startDate)) {
      return false;
    }
    if (endDate && new Date(user.formattedDate) > new Date(endDate)) {
      return false;
    }
    if (branchFilter && !String(user.code).includes(branchFilter)) {
      return false;
    }
    return true;
  });

  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const downloadCSV = () => {
    const csvData = filteredUsers.map((user) => ({
      Date: user.formattedDate,
      Time: user.formattedTime,
      Name: user.displayName,
      'Branch Code': user.code,
      Shares: user.share,
      'Menbership Code': user.membershipCode,
      'Value of Shares': user.price,
      Account: user.account,
      Officer: user.inputter,
    }));
    return csvData;
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widget">
          <div className="left">
            <span className="title">{data.title}</span>
            <span className="link">{data.link}</span>
            {type === 'user' && (
              <div className="allHistory">
                <CSVLink data={downloadCSV()} filename={'transactions.csv'}>
                  Export to CSV
                </CSVLink>
                <h1>Transactions History: </h1>

                <div className="filterSection">
                  <input
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <input
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Search by branch code"
                    onChange={(e) => setBranchFilter(e.target.value)}
                    value={branchFilter}
                  />
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>Date </th>
                      <th>Time</th>
                      <th>Name</th>
                      <th>Membership Code</th>
                      <th>Branch Code</th>
                      <th>Shares</th>
                      <th>Value of Shares</th>
                      <th>Account</th>
                      <th>Inputter</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentUsers.map((user, index) => (
                      <tr key={index}>
                        <td>{user.formattedDate}</td>
                        <td>{user.formattedTime}</td>
                        <td>{user.displayName}</td>
                        <td>{user.membershipCode}</td>
                        <td>{user.code}</td>
                        <td>{user.share} shares</td>
                        <td>GH₵ {user.price}</td>
                        <td>{user.account}</td>
                        <td>{user.inputter}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination">
                  <button
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                      setBranchFilter('');
                    }}
                  >
                    Reset Filters
                  </button>

                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                  >
                    Previous
                  </button>
                  {Array.from({ length: totalPages }).map((_, idx) => (
                    <button
                      key={idx}
                      onClick={() => setCurrentPage(idx + 1)}
                      className={idx + 1 === currentPage ? 'active' : ''}
                    >
                      {idx + 1}
                    </button>
                  ))}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="right">
            <h4>Total Shareholders</h4>
            <div className="userCount">
              <span>{totalUsers} Shareholders</span>
            </div>
            <div className="sharePrice">
              <h4>Current Share Price</h4>
              <div className="price">
                <span>GH₵ 1.00</span>
              </div>
            </div>
            <div className="totalPriceSection">
              <h4>Total Value of Shares</h4>
              <div className="totalPrice">
                <span>GH₵ {totalPrice}</span>
              </div>
            </div>
            <div className="totalVolumeSection">
              <h4>Total Number of Shares</h4>
              <div className="totalVolume">
                <span>{totalVolume} shares</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTransactions;
