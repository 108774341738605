import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const PrivateRoute = ({ children, allowedRoles = [], readOnlyRoles = [] }) => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser || !currentUser.staffData) {
    return <Navigate to="/login" />;
  }

  const userRole = currentUser.staffData.role;

  // Allow access to specified roles and read-only roles
  const hasAccess = [...allowedRoles, ...readOnlyRoles].includes(userRole);

  if (!hasAccess) {
    return <Navigate to="/" />;
  }

  // If the user's role is in readOnlyRoles, pass that information to the child component
  const isReadOnly = readOnlyRoles.includes(userRole);

  // Clone the children and pass the isReadOnly prop
  return React.Children.map(children, (child) => {
    return React.cloneElement(child, { isReadOnly, userRole });
  });
};

export default PrivateRoute;
