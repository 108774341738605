import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { AuthContextProvider } from "./context/AuthContext";
import { SearchProvider } from "./context/SearchContext";
import { TransactionProvider } from "./context/TransactionContext";
import ErrorBoundary from "./components/ErrorBoundary";
import { LoggedUserProvider } from "./context/LoggedUserContext";
ReactDOM.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <AuthContextProvider>
        <SearchProvider>
          <LoggedUserProvider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </LoggedUserProvider>
        </SearchProvider>
      </AuthContextProvider>
    </DarkModeContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
