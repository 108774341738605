import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const useRolePermissions = () => {
  const { currentUser } = useContext(AuthContext);
  const userRole = currentUser?.staffData?.role;

  const isReadOnlyRole = ['audit', 'compliance'].includes(userRole);
  const isSuperUser = userRole === 'super-user';
  const isAdmin = userRole === 'admin';

  const canEdit = !isReadOnlyRole;
  const canDelete = !isReadOnlyRole;
  const canTransfer = !isReadOnlyRole;
  const canApprove = !isReadOnlyRole;

  return {
    isReadOnlyRole,
    isSuperUser,
    isAdmin,
    userRole,
    canEdit,
    canDelete,
    canTransfer,
    canApprove,
  };
};

export default useRolePermissions;
