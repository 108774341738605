import { createContext, useState, useContext } from "react";

const LoggedUserContext = createContext();

export const useLoggedUser = () => {
  return useContext(LoggedUserContext);
};

export const LoggedUserProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState(null); // You can set this from local storage or any mechanism you use

  return (
    <LoggedUserContext.Provider value={{ loggedUser, setLoggedUser }}>
      {children}
    </LoggedUserContext.Provider>
  );
};

export default LoggedUserContext;
