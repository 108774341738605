import React from 'react';
import useRolePermissions from '../../hooks/useRolePermissions';

const ReadOnlyForm = ({ children, onSubmit }) => {
  const { isReadOnlyRole } = useRolePermissions();

  const handleSubmit = (e) => {
    if (isReadOnlyRole) {
      e.preventDefault();
      return;
    }
    onSubmit(e);
  };

  const modifyChildren = (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (
      child.type === 'button' ||
      child.props?.type === 'submit' ||
      child.type?.name === 'Button'
    ) {
      return React.cloneElement(child, {
        disabled: isReadOnlyRole,
        className: `${child.props.className || ''} ${
          isReadOnlyRole ? 'disabled' : ''
        }`,
      });
    }

    if (child.props?.children) {
      const newChildren = React.Children.map(
        child.props.children,
        modifyChildren
      );
      return React.cloneElement(child, {}, newChildren);
    }

    return child;
  };

  return (
    <form onSubmit={handleSubmit}>
      {React.Children.map(children, modifyChildren)}
    </form>
  );
};

export default ReadOnlyForm;
