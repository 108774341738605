import { useState, useEffect } from "react";
import { db, auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const useStaffDetails = () => {
  const [staffDetails, setStaffDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true; // <-- mounted flag

    const currentUserId = auth.currentUser?.uid;

    if (currentUserId) {
      const staffDocRef = doc(db, "staff", currentUserId);

      getDoc(staffDocRef)
        .then((docSnap) => {
          if (isMounted) {
            // <-- check if component is still mounted
            if (docSnap.exists()) {
              setStaffDetails(docSnap.data());
            }
          }
        })
        .catch((err) => {
          if (isMounted) setError(err); // <-- check if component is still mounted
        })
        .finally(() => {
          if (isMounted) setLoading(false); // <-- check if component is still mounted
        });
    } else {
      setLoading(false);
    }

    return () => {
      isMounted = false;
    }; // <-- cleanup: set it to false when component unmounts
  }, []);

  return { staffDetails, loading, error };
};

export default useStaffDetails;
