import Login from './pages/login/Login';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './style/dark.scss';
import { useContext } from 'react';
import { DarkModeContext } from './context/darkModeContext';
import { AuthContext } from './context/AuthContext';
import useAutoLogout from './context/useAutoLogout';
import HomeWrapper from './components/HomeWrapper/HomeWrapper';
import ReadOnlyWrapper from './components/ReadOnlyWrapper/ReadOnlyWrapper';

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { currentUser, dispatch } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  const handleLogout = () => {
    // Notify the user
    setTimeout(() => {
      alert(
        "Logging out in 10 seconds. Press 'OK' if you want to stay logged in."
      );
    }, 0);

    // Start a 10-second countdown
    const logoutTimeout = setTimeout(() => {
      dispatch({ type: 'LOGOUT' });
      localStorage.removeItem('authToken');
    }, 10000); // corrected to 10 seconds

    // Give the user a chance to cancel the automatic logout
    window.onbeforeunload = function () {
      clearTimeout(logoutTimeout);
    };
  };

  useAutoLogout(handleLogout, 10 * 60 * 1000);

  const isReadOnlyUser =
    currentUser?.staffData?.role === 'audit' ||
    currentUser?.staffData?.role === 'compliance';

  return (
    <div className={darkMode ? 'app dark' : 'app'}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <RequireAuth>
                {isReadOnlyUser ? (
                  <ReadOnlyWrapper>
                    <HomeWrapper />
                  </ReadOnlyWrapper>
                ) : (
                  <HomeWrapper />
                )}
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
