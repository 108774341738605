import './userLog.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FaArrowLeft } from 'react-icons/fa';

const UserLog = () => {
  const location = useLocation();
  const transaction = location.state?.transaction;
  const [inputter, setInputter] = useState(null);
  const [reason, setReason] = useState(transaction?.reason);
  const isMounted = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    isMounted.current = true;

    const fetchInputterData = async () => {
      if (transaction?.inputter && !inputter) {
        try {
          const staffDocRef = doc(db, 'staff', transaction.inputter);
          const docSnap = await getDoc(staffDocRef);
          if (docSnap.exists() && isMounted.current) {
            setInputter(docSnap.data().name);
          }
        } catch (error) {
          console.error('Error fetching staff:', error);
        }
      }
    };

    const fetchTransferReason = async () => {
      if (
        transaction?.type === 'transfer' &&
        reason === undefined &&
        transaction.transferId
      ) {
        try {
          const transferDocRef = doc(db, 'transfers', transaction.transferId);
          const transferDocSnap = await getDoc(transferDocRef);
          if (transferDocSnap.exists() && isMounted.current) {
            const transferData = transferDocSnap.data();
            if (transferData?.reason) {
              setReason(transferData.reason);
            }
          }
        } catch (error) {
          console.error('Error fetching transfer reason:', error);
        }
      }
    };

    fetchInputterData();
    fetchTransferReason();

    return () => {
      isMounted.current = false;
    };
  }, [transaction, reason, inputter]);

  if (!transaction) return null;

  return (
    <div className="user-log">
      <button className="backArrow" onClick={() => navigate(-1)}>
        <FaArrowLeft />
      </button>
      <h3>Transaction Log</h3>
      <div className="log-details">
        <p>
          <strong>Date:</strong> {transaction.formattedDate}
        </p>
        <p>
          <strong>Time:</strong> {transaction.formattedTime}
        </p>
        <p>
          <strong>Details:</strong>{' '}
          {transaction.details ||
            (transaction.type === 'transfer' ? 'Transfer' : 'Purchase')}
        </p>
        <p>
          <strong>Balance B/f (Shares):</strong>{' '}
          {Number(transaction.totalShares) - Number(transaction.sharesAdded)}{' '}
          shares
        </p>
        <p>
          <strong>Balance B/f (Amount):</strong> GH₵{' '}
          {Number(transaction.totalPrice) - Number(transaction.priceAdded)}
        </p>
        <p>
          <strong>Shares Added:</strong> {transaction.sharesAdded || '-'}
        </p>
        <p>
          <strong>Amount Added (GH₵):</strong>{' '}
          {transaction.priceAdded ? `GH₵ ${transaction.priceAdded}` : '-'}
        </p>
        <p>
          <strong>Balance A/c (Shares):</strong> {transaction.totalShares}{' '}
          shares
        </p>
        <p>
          <strong>Balance A/c (Amount):</strong> GH₵ {transaction.totalPrice}
        </p>
        {transaction.type === 'transfer' && (
          <p>
            <strong>Reason for Transfer:</strong> {reason}
          </p>
        )}
        {inputter && (
          <p>
            <strong>Inputter:</strong> {inputter}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserLog;
