import './new.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useEffect, useState } from 'react';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  runTransaction,
} from 'firebase/firestore';
import { auth, db, storage } from '../../firebase';
import { signInAnonymously } from 'firebase/auth'; // Use anonymous sign-in
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import useStaffDetails from '../../hooks/useStaffDetails';
import useRolePermissions from '../../hooks/useRolePermissions';
import ReadOnlyForm from '../../components/ReadOnlyForm/ReadOnlyForm';

const New = ({ inputs, title }) => {
  const [file, setFile] = useState('');
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { staffDetails } = useStaffDetails();
  const { isReadOnlyRole } = useRolePermissions();

  useEffect(() => {
    setData((prevData) => ({ ...prevData, trust: 'individual' }));
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      setErrors((prev) => ({
        ...prev,
        file: 'Please select an image or PDF.',
      }));
      return;
    }

    // File Size Validation
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSize) {
      setErrors((prev) => ({
        ...prev,
        file: 'File size should not exceed 5MB.',
      }));
      return;
    }

    // File Type Validation (you can add or remove formats as needed)
    const allowedFormats = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'application/pdf', // allow PDF upload
    ];
    if (!allowedFormats.includes(selectedFile.type)) {
      setErrors((prev) => ({
        ...prev,
        file: 'Please upload a valid file format (JPEG, JPG, PNG, GIF, PDF).',
      }));
      return;
    }

    // If everything's fine, proceed with setting the file
    setFile(selectedFile);
  };

  const handleInputChange = (e, inputId) => {
    handleInput(e);
  };

  useEffect(() => {
    if (!file) return;

    const storageRef = ref(storage, new Date().getTime() + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        setPerc((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setData((prev) => ({ ...prev, img: downloadURL }));
        });
      }
    );
  }, [file]);

  const validateFields = () => {
    let tempErrors = {};

    if (!data.phone || data.phone.length !== 10) {
      tempErrors.phone = 'Please enter a valid 10-digit phone number.';
    }

    if (!data.code || data.code === '') {
      tempErrors.code = 'Please select a branch code.';
    }

    // Add validations for corporation-specific fields
    if (data.trust === 'corporation') {
      if (!data.corpName) {
        tempErrors.corpName = 'Please enter the corporation name.';
      }
      if (!data.doi) {
        tempErrors.doi = 'Please select the date of incorporation.';
      }
      if (!data.tax) {
        tempErrors.tax = 'Please enter the tax identification number.';
      }
      if (!file) {
        tempErrors.file = 'Please upload the certificate of incorporation.';
      }
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    let newErrors = {};

    if (id === 'phone' && value.length > 10) {
      newErrors.phone = 'Phone number must be exactly 10 digits.';
    }

    setData((prevData) => ({ ...prevData, [id]: value }));

    // Clear corporation fields if 'individual' is selected
    if (id === 'trust' && value === 'individual') {
      setData((prevData) => ({
        ...prevData,
        corpName: '', // Clear Name of Corporation
        doi: '', // Clear Date of Incorporation
        tax: '', // Clear Tax Identification No
        certificate: '', // Clear Certificate if it's set
      }));
    }

    setErrors((prev) => ({ ...prev, ...newErrors }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      // 1. Register the user anonymously with Firebase Authentication
      const authRes = await signInAnonymously(auth);

      const updateDatabase = async (transaction) => {
        const lastMembershipRef = doc(db, 'metadata', 'lastMembershipNum');
        const lastMembershipDoc = await transaction.get(lastMembershipRef);

        // Use 0 as default if it doesn't exist (first-time case)
        let lastMembershipNum = lastMembershipDoc.exists()
          ? lastMembershipDoc.data().value
          : 0;
        const newMembershipNum = lastMembershipNum + 1;

        // Generate new membership code
        const membershipCode = `KPRB${String(newMembershipNum).padStart(
          4,
          '0'
        )}`;

        const newUser = {
          ...data,
          uid: authRes.user.uid, // store the user's authentication UID for reference
          membershipCode: membershipCode,
          role: 'user',
          status: 'pending',
          timeStamp: serverTimestamp(),
          addedBy: staffDetails?.name || 'Unknown Staff',
        };

        transaction.set(doc(db, 'users', authRes.user.uid), newUser);

        // Update the membership number
        transaction.set(lastMembershipRef, { value: newMembershipNum });
      };

      // 2. Handle the Firestore transaction for membership and user profile
      await runTransaction(db, updateDatabase);
      navigate(-1);
    } catch (error) {
      console.error('Error adding user:', error);
      setErrors((prev) => ({
        ...prev,
        general: 'An error occurred while registering. Please try again.',
      }));
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=""
            />
          </div>
          <div className="right">
            <ReadOnlyForm onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                {errors.file && <div className="error">{errors.file}</div>}
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === 'select' ? (
                    <select
                      id={input.id}
                      onChange={(e) => handleInput(e)}
                      required={input.isRequired !== false}
                      value={data[input.id] || ''}
                    >
                      {input.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      required={input.isRequired !== false}
                      value={data[input.id] || ''}
                    />
                  )}
                  {errors[input.id] && (
                    <div className="error">{errors[input.id]}</div>
                  )}
                </div>
              ))}

              {/* Conditionally render the corporation-specific fields */}
              {data.trust === 'corporation' && (
                <>
                  <div className="formInput">
                    <label>Name of Corporation</label>
                    <input
                      id="corpName"
                      type="text"
                      placeholder="e.g Kwahu Praso Rural bank Ltd"
                      onChange={handleInput}
                      value={data.corpName || ''}
                    />
                    {errors.corpName && (
                      <div className="error">{errors.corpName}</div>
                    )}
                  </div>
                  <div className="formInput">
                    <label>Date of Incorporation</label>
                    <input
                      id="doi"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      onChange={handleInput}
                      value={data.doi || ''}
                    />
                    {errors.doi && <div className="error">{errors.doi}</div>}
                  </div>
                  <div className="formInput">
                    <label>Tax Identification No:</label>
                    <input
                      id="tax"
                      type="text"
                      placeholder="GH-00-0000"
                      onChange={handleInput}
                      value={data.tax || ''}
                    />
                    {errors.tax && <div className="error">{errors.tax}</div>}
                  </div>
                  <div className="formInput">
                    <label>Certificate of Incorporation</label>
                    <input
                      type="file"
                      id="certificate"
                      onChange={handleFileChange}
                      accept="image/*,application/pdf"
                    />
                    {errors.certificate && (
                      <div className="error">{errors.certificate}</div>
                    )}
                  </div>
                </>
              )}

              {errors.general && <div className="error">{errors.general}</div>}

              <button
                disabled={isReadOnlyRole || (per !== null && per < 100)}
                type="submit"
                className={isReadOnlyRole ? 'disabled' : ''}
              >
                Submit
              </button>
            </ReadOnlyForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
