// ./context/AuthContext.js

import { createContext, useReducer, useEffect } from 'react';

export const AuthContext = createContext();

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem('user')) || null,
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN': {
      return {
        currentUser: action.payload,
      };
    }
    case 'LOGOUT': {
      return {
        currentUser: null,
      };
    }
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
