import './userHistory.scss';
import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, collection, query, getDocs, orderBy } from 'firebase/firestore';
import { FaUser } from 'react-icons/fa';
import Loading from '../Loading/Loading';
import { CSVLink } from 'react-csv'; // CSV Export

const UserHistory = ({ userId }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const transactionsCollection = collection(
          doc(db, 'users', userId),
          'transactions'
        );
        const transactionsQuery = query(
          transactionsCollection,
          orderBy('timeStamp', 'desc')
        );
        const transactionsSnapshot = await getDocs(transactionsQuery);

        const transactionsData = transactionsSnapshot.docs.map((doc) => {
          const data = doc.data();
          const dateObj = data.timeStamp?.toDate();
          data.formattedDate = dateObj?.toLocaleDateString();
          data.formattedTime = dateObj?.toLocaleTimeString();
          return { ...data, id: doc.id };
        });

        setTransactions(transactionsData);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [userId]);

  const filteredTransactions = transactions.filter((transaction) => {
    if (
      startDate &&
      new Date(transaction.formattedDate) < new Date(startDate)
    ) {
      return false;
    }
    if (endDate && new Date(transaction.formattedDate) > new Date(endDate)) {
      return false;
    }
    return true;
  });

  const currentTransactions = filteredTransactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);

  const downloadCSV = () => {
    const csvData = filteredTransactions.map((transaction) => ({
      Date: transaction.formattedDate,
      Time: transaction.formattedTime,
      Details: transaction.details || 'Purchase',
      'Balance B/f (Shares)': transaction.prevShares,
      'Balance B/f (GH₵)': transaction.prevPrice,
      'Shares Added': transaction.sharesAdded,
      'Amount Added (GH₵)': transaction.priceAdded,
    }));
    return csvData;
  };

  if (isLoading) {
    return <Loading />;
  }
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="user-history">
      <h2>User Transaction History</h2>

      {/* Date Filter Section */}
      <div className="filterSection">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Start Date"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="End Date"
        />
        <button
          onClick={() => {
            setStartDate('');
            setEndDate('');
          }}
        >
          Clear Filters
        </button>
      </div>

      {/* Export to CSV */}
      <CSVLink data={downloadCSV()} filename={`transactions_${userId}.csv`}>
        Export to CSV
      </CSVLink>

      {transactions.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Details</th>
                <th>Balance B/f (GH₵)</th>
                <th>Balance B/f (Shares)</th>
                <th>Shares Added</th>
                <th>Price Added (GH₵)</th>
                <th>Log</th>
              </tr>
            </thead>
            <tbody>
              {currentTransactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.formattedDate}</td>
                  <td>{transaction.formattedTime}</td>
                  <td>
                    {transaction.details ||
                      (transaction.type === 'transfer'
                        ? 'Transfer'
                        : 'Purchase')}
                  </td>
                  <td>
                    {/* Balance B/f (Price) */}
                    GH₵{' '}
                    {Number(transaction.totalPrice) -
                      Number(transaction.priceAdded)}
                  </td>
                  <td>
                    {/* Balance B/f (Shares) */}
                    {Number(transaction.totalShares) -
                      Number(transaction.sharesAdded)}{' '}
                    Shares
                  </td>
                  <td>{transaction.sharesAdded || '-'}</td>
                  <td>
                    {transaction.priceAdded
                      ? `GH₵ ${transaction.priceAdded}`
                      : '-'}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/user/log/${transaction.id}`, {
                        state: { transaction },
                      })
                    }
                  >
                    <FaUser className="log-icon" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }).map((_, idx) => (
              <button
                key={idx}
                onClick={() => setCurrentPage(idx + 1)}
                className={idx + 1 === currentPage ? 'active' : ''}
              >
                {idx + 1}
              </button>
            ))}
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <p>No transactions found for this user.</p>
      )}
    </div>
  );
};

export default UserHistory;
